<template>
    <div>
        <b-modal v-model="show" size="md" centered hide-header hide-footer no-close-on-backdrop hide-header-close>
            <div class="p-2">
                <div class="text-center">
                    <app-heading-1 >Upload Document</app-heading-1>
                    <app-normal-text>Upload document and select folder where you want to save</app-normal-text>
                </div>
                <div class="my-2">
                    <vue-dropzone v-if="!file" ref="myFileDropzone" id="dropzone" :options="dropzoneOptions" :useCustomSlot="true" @vdropzone-file-added="checkFileSize">
                        <div class="dropzone-custom-content">
                            <img class="mb-2" :src="require('@/assets/images/app-icons/upload_icon.png')" alt="">
                            <h3 class="dropzone-custom-title">Drop files here or click to upload</h3>
                            <!-- <div class="subtitle">(This is just a demo dropzone. Selected files are not actually uploaded.)</div> -->
                            <div>Only PDF files are allowed (max size upto 250 MB)</div>
                          </div>
                    </vue-dropzone>
                    <div v-else class="d-flex justify-content-between align-items-center pdf-upload">
                        <div class="d-flex align-items-center">
                            <img :src="require('@/assets/images/app-icons/pdf.png')" alt="">
                            <app-normal-text class="ml-1">{{file.name}}</app-normal-text>
                        </div>
                        <div>
                            <feather-icon class="cursor-pointer" icon="TrashIcon" size="22" @click="removeFile"/>
                        </div>
                    </div> 
                </div>
                <div class="row mb-1">
                    <div class="col-5 d-flex align-items-center">
                        <app-label class="mr-1">Final Document</app-label>
                        <b-form-checkbox
                        id="finalDocument"
                        v-model="details.finalDocument"
                        switch
                        />
                        
                    </div>
                    <div class="col-6 d-flex align-items-center">
                        <app-label class="mr-1">Require Signature</app-label>
                        <b-form-checkbox
                        id="requireSignature"
                        v-model="details.required_sign"
                        switch
                        />
                        
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <app-text-input label="Document Title" v-model="details.title" placeholder="Enter"></app-text-input>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" v-if="show_folder & folder.length>1">
                        <app-select-box label="Select Folder" v-model="details.folder"  :options="folder" @onChange="getSubFolders()"></app-select-box>
                    </div>
                    <div class="col-12" v-if="details.folder && show_sub_folder && sub_folder.length>1">
                        <app-select-box label="Select Sub-Folder" v-model="details.sub_folder" :options="sub_folder"></app-select-box>
                    </div>
                </div>
               

                <div class="mt-2 d-flex justify-content-center">
                    <app-simple-button variant="outline-primary" class="mr-1"  @click="hideModal">Cancel</app-simple-button>
                    <app-simple-button variant="primary" :loading="loading" :disabled="!file ||!details.title" @click="moveNext">Next</app-simple-button>    
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
import AppHeading1 from "@/components/app-components/AppHeading1"
import AppNormalText from "@/components/app-components/AppNormalText"
import AppTextInput from "@/components/app-components/AppTextInput"
import AppSelectBox from "@/components/app-components/AppSelectBox"
import AppLabel from "@/components/app-components/AppLabel"
import AppSimpleButton from "@/components/app-components/AppSimpleButton"
import { BModal, VBModal, BFormCheckbox } from 'bootstrap-vue'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import { upload_document,get_folder_list, get_subfolder_list } from "@/apis/others/documents"
export default {
    components: {
        AppHeading1,
        AppTextInput,
        AppSelectBox,
        AppSimpleButton,
        BModal,
        VBModal,
        BFormCheckbox,
        AppLabel,
        AppNormalText,
        vueDropzone: vue2Dropzone,
    },
    directives: {
        'b-modal': VBModal,
        
    },
    data(){
        return {
            show:false,
            dropzoneOptions: {
                autoProcessQueue: false,
                url: 'https://localhost',
                thumbnailWidth: 150,

                maxFiles:1,
                acceptedFiles:'application/pdf'
            },
            details:{
                title:'',
                folder:null,
                sub_folder:null,
                required_sign:false,
                finalDocument:false
            },
            folder:[
                {text:'Select', value:null},
            ],
            sub_folder:[
                {text:'Select', value:null},
            ],
            show_folder:true,
            show_sub_folder:true,
            file:'',
            loading:false,
            show:false,
            errorMessage: '',
        }
    },
    methods:{   
        showModal(){
            this.show = true;
            this.resetInfo()
            if(this.$route.meta.title=='Folder'){
                this.show_folder = false;
                this.details.folder=this.$route.params.id;
                this.getSubFolders()
            }else if(this.$route.meta.title=='SubFolder'){
                this.show_folder = false;
                this.show_sub_folder = false;
                this.details.sub_folder=this.$route.params.id;
                this.details.folder=this.$route.params.folderId;
            }else{
                this.show_folder = true;
                this.show_sub_folder = true;
                this.getFolders()
            }
            
        },
        hideModal(){
            this.show=false;
            this.show_folder = true;
            this.show_sub_folder = true;
        },
        moveNext(){
            this.loading=true;
            let data= new FormData();
            data.append('file',this.file);
            data.append('documentName',this.details.title);
            data.append('signaturesRequired',this.details.required_sign);
            data.append('finalDocument',this.details.finalDocument);
            if(this.details.folder||this.details.sub_folder){
                data.append('folder',this.details.sub_folder?this.details.sub_folder:this.details.folder);
                data.append('project',this.details.folder?this.details.folder:null);
            }
            upload_document(data).then(resp=>{
                this.loading=false;
                this.$router.push({path:`/document/details/${resp.data.newDocument._id}`, query:{requireSignatures:this.details.required_sign?1:0}});
                this.show=false;
            }).catch(err=>{
                this.$bvToast.toast(err.message?err.message:'Unexpected error occured please try again', {
                        title: err.error?err.error:'Error',
                        variant: 'danger',
                        solid: true,
                    })
                    this.loading=false;
            })
        },
        checkFileSize(e){
            if(e.size / Math.pow(1024, 2)<=250){
                this.addFile(e)
            }else{
                this.$refs.myFileDropzone.removeFile(e);
                this.$bvToast.toast('File size should be less than 250 MB', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                })
                this.file=''
            }
        },
        addFile(file){
            if(file.type=='application/pdf'){
               
                this.file=file;
            }else{
                this.$refs.myFileDropzone.removeFile(file);
                this.$bvToast.toast('Please upload pdf files only.', {
                    title: 'Error',
                    variant: 'danger',
                    solid: true,
                })
                this.file=''
            }
        },
        removeFile(){
            this.file=''
        },
        getFolders(){
            this.folder.splice(1);
            get_folder_list().then(response=>{
                response.data.map(f=>{
                    let obj={
                        text:f.name,
                        value:f._id
                    }
                    this.folder.push(obj);
                })   
                
            })
        },
        getSubFolders(){
            this.sub_folder.splice(1);
            get_subfolder_list(this.details.folder).then(response=>{
                response.data.map(f=>{
                    let obj={
                        text:f.name,
                        value:f._id
                    }
                    this.sub_folder.push(obj);
                })
            })
        },
        resetInfo(){
            this.details.title='';
            this.details.folder=null;
            this.details.sub_folder=null;
            this.details.required_sign=false;
            this.details.finalDocument=false;
            this.file=''

        },
    },
}
</script>
<style>
  .dropzone-custom-title{
    color: #4B465C;
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
  }
  .subtitle{
    color: #4B465C;
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
  }
  .icon-circle{
    padding: 10px;
    background-color: '#F6F7F9';
    border-radius: 50%;
  }
  .vue-dropzone{
    border: 1px dashed #DBDADE;
    border-radius: 6px;
    background-color: #ffffff;
  }
  .btn-outline-primary:focus{
    background-color: transparent !important;
  }
</style>